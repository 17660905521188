import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionalsAreas = []

const getterAreas = () => {
  return api.Areas.byType('federalState').then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return { federalStates: result.data.data }
  })
}

export const [getAreas, bustAreas] = createDataCache(conditionalsAreas, getterAreas, {
  name: 'ScoringCacheAreas',
})

const conditionalsComparableDataSources = []

const getterComparableDataSource = () => {
  return api.Config.byType('scoringDataSources').then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return { comparableDataSources: result.data.data }
  })
}

export const [getComparableDataSources, bustComparableDataSources] = createDataCache(
  conditionalsComparableDataSources,
  getterComparableDataSource,
  {
    name: 'ScoringCacheComparableDataSources',
  }
)

const conditionalsScores = [{ name: 'assetClass' }]

const getterScores = (assetClass) => {
  return api.Scoring.getScores(assetClass).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getScores, bustScores] = createDataCache(conditionalsScores, getterScores, {
  name: 'ScoringCacheScores',
})

const conditionalsClassifiedScores = [{ name: 'assetClass' }, { name: 'cluster' }]

const getterClassifiedScores = (assetClass, cluster) => {
  return api.Scoring.getClassifiedScores(assetClass, cluster).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getClassifiedScores, bustClassifiedScores] = createDataCache(
  conditionalsClassifiedScores,
  getterClassifiedScores,
  {
    name: 'ScoringCacheClassifiedScores',
  }
)

const conditionalsYieldData = [{}]

const getterYieldData = () => {
  return api.Scoring.getComparables().then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getYieldData, bustYieldData] = createDataCache(conditionalsYieldData, getterYieldData, {
  name: 'ScoringCacheYieldData',
})

const conditionalsDetails = [{ name: 'gac' }]

const getterDetails = (assetClass) => {
  return api.Scoring.getDetails(assetClass).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

export const [getDetails, bustDetails] = createDataCache(conditionalsDetails, getterDetails, {
  name: 'ScoringCacheDetails',
})

const conditionalsDetail = [{ name: 'gac' }]

const getterDetail = (gac) => {
  return api.Scoring.getDetail(gac).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsDocumentList = [{ name: 'type' }]
const getterDocumentList = (type) => {
  return api.Downloads.getDocuments(type).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getDetail, bustDetail] = createDataCache(conditionalsDetail, getterDetail, {
  name: 'ScoringCacheDetail',
})

export const [getDocumentList, bustDocumentList] = createDataCache(
  conditionalsDocumentList,
  getterDocumentList,
  {
    name: 'ScoringDocumentList',
  }
)
